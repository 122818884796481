import {
  useFiefAuth,
  useFiefIsAuthenticated,
  useFiefUserinfo,
  useFiefTokenInfo,
} from '@fief/fief/react';
import React, { useEffect } from 'react';

const RequireAuth = ({ children }) => {
  const fiefAuth = useFiefAuth();
  const isAuthenticated = useFiefIsAuthenticated();
  const userInfo = useFiefUserinfo();
  const tokenInfo = useFiefTokenInfo();

  useEffect(() => {
    if (!isAuthenticated) {
      fiefAuth.redirectToLogin(
        `${window.location.protocol}//${window.location.host}/callback`,
      );
    } else {
      localStorage.setItem('accessToken', tokenInfo?.access_token);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      localStorage.setItem('userRole', userInfo?.fields?.role);
      // localStorage.setItem('userId', userInfo?.sub);
      localStorage.setItem('userId', 'test');
    }
  }, [fiefAuth, isAuthenticated, tokenInfo, userInfo]);

  return <>{isAuthenticated && children}</>;
};

export default RequireAuth;
