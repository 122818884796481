import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, List, ListItem, ListItemButton, IconButton, Button, TextField, FormControlLabel, Collapse, Checkbox } from '@mui/material';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';
import styles from '../assets/css/pages/CompareFilesPage.module.css';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CONSTANTS from '../config/CONSTANTS';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const CompareFilesPage = () => {
  const location = useLocation();
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file1Name, setFile1Name] = useState(null);
  const [file2Name, setFile2Name] = useState(null);
  const [diffs, setDiffs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rulebook, setRulebook] = useState("None");
  const [rulebookModalOpen, setRulebookModalOpen] = useState(false);
  const [savedRulebooks, setSavedRulebooks] = useState([]);
  const [rulebookPreviewModalOpen, setRulebookPreviewModalOpen] = useState(false);
  const [rulebookPreviewContent, setRulebookPreviewContent] = useState('');
  const [chatInputs, setChatInputs] = useState({});
  const [reanalysisResults, setReanalysisResults] = useState({});
  const [isReanalyzing, setIsReanalyzing] = useState({});
  const [showChat, setShowChat] = useState({});
  const [showRulebooks, setShowRulebooks] = useState(true);

  const { doc1, doc2 } = location.state || {};

  var currentDiffIndex = null;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const displayHtml = (html, elementId) => {
    // Add the style tag to the HTML content
    const styledHtml = `<style>
      .diff-highlight {
        background-color: yellow;
      }
    </style>${html}`;
    document.getElementById(elementId).srcdoc = styledHtml;
  };

  const analyzeDiff = async (before, after, summary, rules, index) => {
    try {
      const response = await fetch(CONSTANTS['endpoint_process_diff'], {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          before: before,
          after: after,
          change_summary: summary,
          rulebook: rules === "None" ? [] : Array.isArray(rules) ? rules : [rules],
          uid: localStorage.getItem('userId')
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error analyzing diff:', error);
      return null; // Or handle the error in a more specific way
    }
  };

  const scrollToDiff = (index) => {
    // Remove highlight from previous diff
    if (currentDiffIndex !== null) {
      removeHighlight(currentDiffIndex);
    }
    currentDiffIndex = index;

    const html1 = document.getElementById('html1');
    const html2 = document.getElementById('html2');

    const iframeDoc1 = html1.contentDocument;
    const iframeDoc2 = html2.contentDocument;

    const elements1 = iframeDoc1.querySelectorAll(`[data-diff-id="${index}"]`);
    const elements2 = iframeDoc2.querySelectorAll(`[data-diff-id="${index}"]`);

    if (elements1.length > 0) {
      elements1[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    elements1.forEach((element) => {
      element.classList.add('diff-highlight');
    });

    if (elements2.length > 0) {
      elements2[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    elements2.forEach((element) => {
      element.classList.add('diff-highlight');
    });
  };

  const removeHighlight = () => {
    if (currentDiffIndex !== null) {
      const html1 = document.getElementById('html1');
      const html2 = document.getElementById('html2');

      const iframeDoc1 = html1.contentDocument;
      const iframeDoc2 = html2.contentDocument;

      const elements1 = iframeDoc1.querySelectorAll(
        `[data-diff-id="${currentDiffIndex}"]`
      );
      const elements2 = iframeDoc2.querySelectorAll(
        `[data-diff-id="${currentDiffIndex}"]`
      );

      elements1.forEach((element) => {
        element.classList.remove('diff-highlight');
      });

      elements2.forEach((element) => {
        element.classList.remove('diff-highlight');
      });
    }
  };

  const analyzeAllDiffs = async () => {
    if (!file1 || !file2) {
      return;
    }

    setIsLoading(true); // Set loading indicator to true

    try {
      for (let i = 0; i < diffs.length; i++) {
        const diff = diffs[i];
        const analyzeRiskButton = document.getElementById(`analyze-risk-button-${i}`);
        analyzeRiskButton.textContent = 'Analyzing...';
        analyzeRiskButton.disabled = true;
        const response = await analyzeDiff(diff.before, diff.after, diff.summary_plain, rulebook, i);
        analyzeRiskButton.textContent = "Re-analyze";
        analyzeRiskButton.disabled = false;
        if (response) {
          const result = response;
          var risk_level_html = '';
          if (result.risk_level && result.risk_level.toLowerCase().includes('high')) {
            risk_level_html = '<b style="color: red;">High</b>';
          } else if (result.risk_level && result.risk_level.toLowerCase().includes('medium')) {
            risk_level_html = '<b style="color: #ff9933;">Medium</b>';
          } else if (result.risk_level && result.risk_level.toLowerCase().includes('low')) {
            risk_level_html = '<b style="color: green;">Low</b>';
          } else {
            risk_level_html = '<b style="color: grey;">Unknown</b>';
          }
          const analysis = document.getElementById(`analysis-${i}`);
          analysis.innerHTML = `</br><b>Risk level:</b> ${risk_level_html}</br></br><b>Comment:</b> ${result.comment}</br></br><b>Rules applied:</b> ${result.rules}</br></br>`;
        }
      }
    } catch (error) {
      console.error('Error analyzing all diffs:', error);
    } finally {
      setIsLoading(false); // Set loading indicator to false
    }
  };


  const fetchSavedRulebooks = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${CONSTANTS['endpoint_fetch_rulebooks']}?uid=${localStorage.getItem('userId')}&subSystem=MyLib`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setSavedRulebooks(data.rulebook_ids);
    } catch (error) {
      console.error('Error fetching saved rulebooks:', error);
    }
  };

  const uploadRulebook = async (event) => {
    const file = event.target.files[0];
    const accessToken = localStorage.getItem('accessToken');
    if (file) {
      const formData = new FormData();
      formData.append('files', file, file.fileName);
      formData.append('uid', localStorage.getItem('userId'));
      formData.append('subSystem', 'MyLib');

      try {
        const response = await fetch(CONSTANTS['endpoint_upload_rulebook'], {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setRulebook(data.rulebook_id);
        fetchSavedRulebooks();
      } catch (error) {
        console.error('Error uploading rulebook:', error);
      }
    }
  };

  const deleteRulebook = async (rulebookId) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${CONSTANTS['endpoint_delete_rulebook']}/${rulebookId}?uid=${localStorage.getItem('userId')}&subSystem=MyLib`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          // Handle rulebook not found
          alert("Rulebook not found");
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }
      // Refresh the list of rulebooks after successful deletion
      fetchSavedRulebooks();
    } catch (error) {
      console.error('Error deleting rulebook:', error);
      alert('Failed to delete rulebook. Please try again.');
    }
  };

  const fetchRulebookContent = async (rulebookId) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${CONSTANTS['endpoint_fetch_rulebook']}/${rulebookId}?uid=${localStorage.getItem('userId')}&subSystem=MyLib`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRulebookPreviewContent(data.contents);
      // setRulebookModalOpen(false);
      setRulebookPreviewModalOpen(true);
    } catch (error) {
      console.error('Error fetching rulebook content:', error);
      alert('Failed to fetch rulebook content. Please try again.');
    }
  };

  useEffect(() => {
    fetchSavedRulebooks();
    const fetchData = async () => {
      if (doc1 && doc2) {
        setIsLoading(true);
        try {
          setFile1Name(doc1.fileName);
          setFile2Name(doc2.fileName);
          setFile1(doc1.docId);
          setFile2(doc2.docId);

          const req_body = JSON.stringify({
            docId1: doc1.docId,
            docId2: doc2.docId,
            uid: localStorage.getItem('userId'),
            subsystem_id: "MyLib"
          })
          const response = await fetch(CONSTANTS['endpoint_compare'], {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: req_body,
            timeout: 600
          });

          const result = await response.json();
          setDiffs(result.diffs);
          displayHtml(result.html1, 'html1');
          displayHtml(result.html2, 'html2');
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
        setIsLoading(false);

      }
    };
    fetchData();
  }, [doc1, doc2]);

  const handleRulebookSelect = (event) => {
    const selectedValue = event.target.value;
    setRulebook(prevRulebook => {
      if (prevRulebook === "None") {
        return [selectedValue];
      }
      if (Array.isArray(prevRulebook)) {
        if (prevRulebook.includes(selectedValue)) {
          return prevRulebook.filter(item => item !== selectedValue);
        } else {
          return [...prevRulebook, selectedValue];
        }
      }
      return [selectedValue];
    });
  };

  const handleRulebookDeselect = () => {
    setRulebook("None");
  };

  const handleChatSubmit = async (index, additionalContext) => {
    const diff = diffs[index];
    const analysisElement = document.getElementById(`analysis-${index}`);
    let originalRiskLevel = null;
    let originalComment = null;

    if (analysisElement) {
      // Extract original risk level and comment from the analysis element's innerHTML
      const riskLevelMatch = analysisElement.innerHTML.match(/<b>Risk level:<\/b> (.*?)<br>/);
      const commentMatch = analysisElement.innerHTML.match(/<b>Comment:<\/b> (.*?)<br>/);

      if (riskLevelMatch) {
        originalRiskLevel = riskLevelMatch[1].trim();
        // Remove HTML tags from originalRiskLevel
        originalRiskLevel = originalRiskLevel.replace(/<\/?[^>]+(>|$)/g, "");
      }
      if (commentMatch) {
        originalComment = commentMatch[1].trim();
        // Remove HTML tags from originalComment
        originalComment = originalComment.replace(/<\/?[^>]+(>|$)/g, "");
      }
    }

    setIsReanalyzing({ ...isReanalyzing, [index]: true });

    try {
      const response = await fetch(CONSTANTS['endpoint_reanalyze_diff_item'], {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          "originalDiff": diff,
          "additionalContext": additionalContext,
          "rulebook": rulebook,
          "uid": localStorage.getItem('userId'),
          "originalRiskLevel": originalRiskLevel,
          "originalComment": originalComment,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reanalysisResult = await response.json();
      setReanalysisResults({ ...reanalysisResults, [index]: reanalysisResult });
    } catch (error) {
      console.error('Error re-analyzing diff item:', error);
    } finally {
      setIsReanalyzing({ ...isReanalyzing, [index]: false });
    }
  };

  return (
    <Box>
      <Header pageTitle="Compare Drafts" showBackHome={true} />

      <div className={styles.compareFilesPage}>
        <div id="main-content" className={styles.mainContent}>
          <div className={styles.column}>
            <label className={styles.fileLabel} id="file1Label">
              {file1Name ? file1Name : "Select a file"}
            </label>
            <iframe id="html1" className={styles.htmlView} title="File 1"></iframe>
          </div>
          <div className={styles.column}>
            <label className={styles.fileLabel} id="file2Label">
              {file2Name ? file2Name : "Select a file"}
            </label>
            <iframe id="html2" className={styles.htmlView} title="File 2"></iframe>
          </div>
        </div>

        <div id="sidebar" className={styles.sidebar}>
          <Button variant="contained" onClick={analyzeAllDiffs} style={{ width: "98%", margin: "1%" }} disabled={isLoading}>
            {isLoading ? 'Analyzing Changes...' : 'Analyze All Changes'}
          </Button>
          <Button variant="contained" style={{ width: "98%", margin: "1%" }} onClick={() => setRulebookModalOpen(true)}>
            Manage Rulebooks
          </Button>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingX: '8px', paddingTop: '8px' }}>
            <Typography variant="h6">Rulebooks</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '16px' }}>
              {rulebook !== "None" && (
                <Button onClick={handleRulebookDeselect}>Deselect</Button>
              )}
              {showRulebooks ? <ExpandLessIcon onClick={() => setShowRulebooks(!showRulebooks)} /> : <ExpandMoreIcon onClick={() => setShowRulebooks(!showRulebooks)} />}
            </Box>
          </Box>
          <Collapse in={showRulebooks}>
            {savedRulebooks.map((rulebookItem) => (
              <ListItem key={rulebookItem} disablePadding>
                <ListItemButton sx={{ width: '100%' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Array.isArray(rulebook) ? rulebook.includes(rulebookItem) : rulebook === rulebookItem}
                        onChange={handleRulebookSelect}
                        value={rulebookItem}
                      />
                    }
                    label={rulebookItem}
                    sx={{ width: '100%' }}
                  />
                  <Button size="small" onClick={() => fetchRulebookContent(rulebookItem)} sx={{ ml: 2 }}>Preview</Button>
                </ListItemButton>
              </ListItem>
            ))}
          </Collapse>
          {/* Rulebook Modal using MUI */}
          <Modal
            open={rulebookPreviewModalOpen}
            onClose={() => {
              setRulebookPreviewModalOpen(false);
            }}
            aria-labelledby="rulebook-preview-modal-title"
            aria-describedby="rulebook-preview-modal-description"
          >
            <Box sx={style} style={{ maxHeight: '80vh', width: '600px' }}>
              <Typography id="rulebook-preview-modal-title" variant="h6" component="h2">
                Rulebook Preview
              </Typography>
              <Button onClick={() => {
                setRulebookPreviewModalOpen(false);
                // setRulebookModalOpen(true);
              }} style={{ position: 'absolute', top: '20px', right: '20px' }}>Close</Button>
              <Typography id="rulebook-preview-modal-description" style={{ maxHeight: '75vh', overflowY: 'auto', whiteSpace: 'pre-line', margin: '8px auto' }}>
                {rulebookPreviewContent}
              </Typography>
            </Box>
          </Modal>
          <Modal
            open={rulebookModalOpen}
            onClose={() => setRulebookModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Apply rulebooks
              </Typography>
              <List>
                <div style={{ maxHeight: '200px', overflowY: 'auto', boxShadow: 'inset 0 2px 5px rgba(0,0,0,0.1)' }}>
                  {savedRulebooks.map((rulebookItem) => (
                    <ListItem key={rulebookItem} disablePadding>
                      <ListItemButton sx={{ width: '100%' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Array.isArray(rulebook) ? rulebook.includes(rulebookItem) : rulebook === rulebookItem}
                              onChange={handleRulebookSelect}
                              value={rulebookItem}
                            />
                          }
                          label={rulebookItem}
                          sx={{ width: '100%' }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                          <IconButton onClick={() => deleteRulebook(rulebookItem)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  ))}</div>
              </List>
              <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
                Upload New Rulebook
              </Typography>
              <TextField
                type="file"
                accept=".txt"
                onChange={uploadRulebook}
                sx={{ mt: 1, boxShadow: 'inset 0 2px 5px rgba(0,0,0,0.1)' }}
              />
              <Button variant="contained" onClick={() => setRulebookModalOpen(false)} sx={{ mt: 2 }}>
                Confirm
              </Button>
            </Box>
          </Modal>
          <div id="diff-list" className={styles.diffList}>
            {diffs.map((diff, index) => (
              <div key={index} className={styles.diffItem}>
                <div dangerouslySetInnerHTML={{ __html: diff.summary }} /> <br />
                < div id={`analysis-${index}`}>
                  {/* ... placeholder for analysis results ... */}
                </div>
                <Button variant='outlined' style={{ margin: '1%' }} onClick={() => scrollToDiff(index)}>Locate</Button>
                <Button variant='outlined' style={{ margin: '1%' }} id={`analyze-risk-button-${index}`} onClick={async () => {
                  const analyzeRiskButton = document.getElementById(`analyze-risk-button-${index}`);
                  analyzeRiskButton.textContent = 'Analyzing...';
                  analyzeRiskButton.disabled = true;
                  const response = await analyzeDiff(diff.before, diff.after, diff.summary_plain, rulebook, index);
                  analyzeRiskButton.textContent = "Re-analyze";
                  analyzeRiskButton.disabled = false;
                  if (response) {
                    const result = response;
                    var risk_level_html = '';
                    if (result.risk_level && result.risk_level.toLowerCase().includes('high')) {
                      risk_level_html = '<b style="color: red;">High</b>';
                    } else if (result.risk_level && result.risk_level.toLowerCase().includes('medium')) {
                      risk_level_html = '<b style="color: #ff9933;">Medium</b>';
                    } else if (result.risk_level && result.risk_level.toLowerCase().includes('low')) {
                      risk_level_html = '<b style="color: green;">Low</b>';
                    } else {
                      risk_level_html = '<b style="color: grey;">Unknown</b>';
                    }
                    const analysis = document.getElementById(`analysis-${index}`);
                    analysis.innerHTML = `</br><b>Risk level:</b> ${risk_level_html}</br></br><b>Comment:</b> ${result.comment}</br></br><b>Rules applied:</b> ${result.rules}</br></br>`;
                  }
                }}>Analyze</Button>
                <Button variant='outlined' style={{ margin: '2px' }} onClick={() => {
                  setShowChat({ ...showChat, [index]: !showChat[index] });
                }}>
                  {showChat[index] ? 'Hide Chat' : 'Chat'}
                </Button>
                {showChat[index] && (
                  <div>
                    <TextField
                      value={chatInputs[index] || ''}
                      onChange={(e) => setChatInputs({ ...chatInputs, [index]: e.target.value })}
                      label="Additional Context"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                    />
                    <Button variant='contained' onClick={() => handleChatSubmit(index, chatInputs[index])} disabled={isReanalyzing[index]}>
                      {isReanalyzing[index] ? 'Re-analyzing...' : 'Submit'}
                    </Button>
                  </div>
                )}
                {reanalysisResults[index] && (
                  <div>
                    <Typography><b>Re-analysis Result:</b></Typography>
                    <Typography><b>Risk Level: </b>{reanalysisResults[index].risk_level}</Typography>
                    <Typography><b>Comment: </b>{reanalysisResults[index].comment}</Typography>
                    <Typography><b>Rules applied: </b>{reanalysisResults[index].rules}</Typography>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Box>

  );
};

export default CompareFilesPage;