let BACKEND_SERVER_DOMAIN;
let BACKEND_SERVER_PORT;
let CONSTANTS;
let SERVER_ENDPOINT;

if (process.env.REACT_APP_MODE === 'dev') {
  // SERVER_ENDPOINT = 'https://dev-api.idoc.work';
  // SERVER_ENDPOINT = 'http://localhost:9101';
  SERVER_ENDPOINT = 'https://dev-api.idoc.work';
  CONSTANTS = {
    // ENDPOINT_LOGIN: SERVER_ENDPOINT + '/user/auth',
    // endpoint_get_file_content: SERVER_ENDPOINT + '/doc/',
    ENDPOINT_GET_FILE: SERVER_ENDPOINT + '/doc/',
    endpoint_get_file_html: SERVER_ENDPOINT + '/doc/html',
    endpoint_get_file_md: SERVER_ENDPOINT + '/doc/md',
    endpoint_get_all_files: SERVER_ENDPOINT + '/doc/allUserDocument',
    endpoint_upload_file: SERVER_ENDPOINT + '/doc/MyLib',
    endpoint_upload_rulebook: SERVER_ENDPOINT + '/uploadRulebook',
    endpoint_fetch_rulebooks: SERVER_ENDPOINT + '/fetchRulebooks',
    endpoint_delete_rulebook: SERVER_ENDPOINT + '/deleteRulebook',
    endpoint_fetch_rulebook: SERVER_ENDPOINT + '/fetchRulebookContent',
    endpoint_fetch_chat: SERVER_ENDPOINT + '/fetchChat',
    endpoint_save_chat: SERVER_ENDPOINT + '/saveChat',
    endpoint_fetch_autofill: SERVER_ENDPOINT + '/fetchAutofill',
    endpoint_save_autofill: SERVER_ENDPOINT + '/saveAutofill',
    endpoint_fetch_risk_analysis: SERVER_ENDPOINT + '/fetchRiskAnalysis',
    endpoint_save_risk_analysis: SERVER_ENDPOINT + '/saveRiskAnalysis',
    endpoint_reanalyze_risk_item: SERVER_ENDPOINT + '/reanalyzeRiskItem',
    endpoint_delete_file: SERVER_ENDPOINT + '/doc',
    endpoint_compare: SERVER_ENDPOINT + '/compare',
    endpoint_analyze_file: SERVER_ENDPOINT + '/analyzeFile',
    endpoint_process_diff: SERVER_ENDPOINT + '/process_diff',
    endpoint_reanalyze_diff_item: SERVER_ENDPOINT + '/reanalyzeDiff',
    // endpoint_get_preset_task: SERVER_ENDPOINT + '/query/userDocs',
    endpoint_get_preset_task: SERVER_ENDPOINT + '/query/userDocsNoRag',
    endpoint_get_compare: SERVER_ENDPOINT + '/query',
    endpoint_reset: SERVER_ENDPOINT + '/base/reset',
    endpoint_build: SERVER_ENDPOINT + '/base/build',
    'chat-query-questions':
      'Hi, Please just return 4 questions about documents',
    'chat-query-summarization': 'Hi, tell me about documents',
    'delete-success-message': 'success',
  };
} else if (process.env.REACT_APP_MODE === 'production') {
  SERVER_ENDPOINT = 'https://api.idoc.work';
  CONSTANTS = {
    endpoint_get_file_content: SERVER_ENDPOINT + '/doc/',
    endpoint_get_file_html: SERVER_ENDPOINT + '/doc/html',
    endpoint_get_all_files: SERVER_ENDPOINT + '/doc/allUserDocument',
    endpoint_upload_file: SERVER_ENDPOINT + '/doc/MyLib',
    endpoint_upload_rulebook: SERVER_ENDPOINT + '/uploadRulebook',
    endpoint_fetch_rulebooks: SERVER_ENDPOINT + '/fetchRulebooks',
    endpoint_delete_rulebook: SERVER_ENDPOINT + '/deleteRulebook',
    endpoint_fetch_chat: SERVER_ENDPOINT + '/fetchChat',
    endpoint_save_chat: SERVER_ENDPOINT + '/saveChat',
    endpoint_fetch_autofill: SERVER_ENDPOINT + '/fetchAutofill',
    endpoint_save_autofill: SERVER_ENDPOINT + '/saveAutofill',
    endpoint_fetch_risk_analysis: SERVER_ENDPOINT + '/fetchRiskAnalysis',
    endpoint_save_risk_analysis: SERVER_ENDPOINT + '/saveRiskAnalysis',
    endpoint_reanalyze_risk_item: SERVER_ENDPOINT + '/reanalyzeRiskItem',
    endpoint_delete_file: SERVER_ENDPOINT + '/doc',
    endpoint_compare: SERVER_ENDPOINT + '/compare',
    endpoint_analyze_file: SERVER_ENDPOINT + '/analyzeFile',
    endpoint_process_diff: SERVER_ENDPOINT + '/process_diff',
    endpoint_reanalyze_diff_item: SERVER_ENDPOINT + '/reanalyzeDiff',
    // endpoint_get_preset_task: SERVER_ENDPOINT + '/query/userDocs',
    endpoint_get_preset_task: SERVER_ENDPOINT + '/query/userDocsNoRag',
    endpoint_reset: SERVER_ENDPOINT + '/base/reset',
    endpoint_build: SERVER_ENDPOINT + '/base/build',
    'chat-query-questions':
      'Hi, Please just return 4 questions about documents',
    'chat-query-summarization': 'Hi, tell me about documents',
    'delete-success-message': 'success',
  };
} else {
  // DEPRECATED
  BACKEND_SERVER_DOMAIN = 'http://localhost';
  BACKEND_SERVER_PORT = '8000';
  SERVER_ENDPOINT = BACKEND_SERVER_DOMAIN + ':' + BACKEND_SERVER_PORT;
  CONSTANTS = {
    endpoint_get_file_content: SERVER_ENDPOINT + '/get_file',
    endpoint_get_all_files: SERVER_ENDPOINT + '/get_all_files_info',
    endpoint_upload_file: SERVER_ENDPOINT + '/upload_file',
    endpoint_delete_file: SERVER_ENDPOINT + '/delete_file',
    endpoint_get_preset_task: SERVER_ENDPOINT + '/get_preset_task_response',
    'chat-query-questions':
      "Please just return 4 questions about legal subjects randomly. Don't apologize",
    'chat-query-summarization':
      'Please just return an summarization response about the latest legal reference',
    'delete-success-message': 'success',
  };
}

export default CONSTANTS;
