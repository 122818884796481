import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Button, TextField, FormControlLabel, List, ListItem, ListItemButton, Modal, IconButton, Collapse, Checkbox } from '@mui/material';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';
import styles from '../assets/css/pages/RiskAnalysisPage.module.css';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CONSTANTS from '../config/CONSTANTS';
import FileViewer from '../components/FileViewer';
import { fetchFileUrl } from '../utils/ViewFileHelper';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const FileViewerMemoized = React.memo(FileViewer);

export function fuzzyMatch(target, content) {
    // Remove all non-alphabetic characters from target
    const sanitizedTarget = target.replace(/[^a-zA-Z]/g, "");

    // Create a RegExp pattern that ignores spaces and other non-alphabetic characters in content
    const pattern = new RegExp(sanitizedTarget.split("").join("[^a-zA-Z]*"), "i");

    // Perform the match
    const match = String(content).match(pattern);

    return match ? match[0] : null; // use the result in your CMD + F
}
const RiskAnalysisPage = () => {
    const location = useLocation();
    const [file, setFile] = useState(null);
    const [analysisResult, setAnalysisResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rulebook, setRulebook] = useState("None");
    const [rulebookModalOpen, setRulebookModalOpen] = useState(false);
    const [savedRulebooks, setSavedRulebooks] = useState([]);
    const [fileURL, setFileURL] = useState('');
    const { doc } = location.state || {};
    const [docContents, setDocContents] = useState();
    const [chatInputs, setChatInputs] = useState({});
    const [reanalysisResults, setReanalysisResults] = useState({});
    const [isReanalyzing, setIsReanalyzing] = useState({});
    const [showChat, setShowChat] = useState({});
    const [showRulebooks, setShowRulebooks] = useState(true);
    const [rulebookPreviewModalOpen, setRulebookPreviewModalOpen] = useState(false);
    const [rulebookPreviewContent, setRulebookPreviewContent] = useState('');



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const analyzeFile = async () => {
        if (!file) {
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(CONSTANTS['endpoint_analyze_file'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "doc_id": file,
                    "uid": localStorage.getItem('userId'),
                    "subsystem_id": 'MyLib',
                    "content": docContents,
                    "rulebook": rulebook === "None" ? [] : Array.isArray(rulebook) ? rulebook : [rulebook]
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            console.log("[INFO] Analysis result:")
            console.log(result)
            if (result) {
                setAnalysisResult(result);
                setReanalysisResults({});
            }
        } catch (error) {
            console.error('Error analyzing file:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSavedRulebooks = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${CONSTANTS['endpoint_fetch_rulebooks']}?uid=${localStorage.getItem('userId')}&subSystem=MyLib`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            setSavedRulebooks(data.rulebook_ids);
        } catch (error) {
            console.error('Error fetching saved rulebooks:', error);
        }
    };

    const uploadRulebook = async (event) => {
        const file = event.target.files[0];
        const accessToken = localStorage.getItem('accessToken');
        if (file) {
            const formData = new FormData();
            formData.append('files', file, file.fileName);
            formData.append('uid', localStorage.getItem('userId'));
            formData.append('subSystem', 'MyLib');


            try {
                const response = await fetch(CONSTANTS['endpoint_upload_rulebook'], {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setRulebook(data.rulebook_id); // Or appropriate identifier
                fetchSavedRulebooks();
            } catch (error) {
                console.error('Error uploading rulebook:', error);
            }
        }
    };

    const deleteRulebook = async (rulebookId) => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${CONSTANTS['endpoint_delete_rulebook']}/${rulebookId}?uid=${localStorage.getItem('userId')}&subSystem=MyLib`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    "uid": localStorage.getItem('userId'),
                    "subSystem": 'MyLib',
                })
            });

            if (!response.ok) {
                if (response.status === 404) {
                    // Handle rulebook not found
                    alert("Rulebook not found");
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            }
            // Refresh the list of rulebooks after successful deletion
            fetchSavedRulebooks();
        } catch (error) {
            console.error('Error deleting rulebook:', error);
            alert('Failed to delete rulebook. Please try again.');
        }
    };

    const handleRulebookSelect = (event) => {
        const selectedValue = event.target.value;
        setRulebook(prevRulebook => {
            if (prevRulebook === "None") {
                return [selectedValue];
            }
            if (Array.isArray(prevRulebook)) {
                if (prevRulebook.includes(selectedValue)) {
                    return prevRulebook.filter(item => item !== selectedValue);
                } else {
                    return [...prevRulebook, selectedValue];
                }
            }
            return [selectedValue];
        });
    };

    const handleRulebookDeselect = () => {
        setRulebook("None");
    };

    const fetchRulebookContent = async (rulebookId) => {
        console.log("[INFO] Fetching rulebook contents for " + rulebookId);
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`${CONSTANTS['endpoint_fetch_rulebook']}/${rulebookId}?uid=${localStorage.getItem('userId')}&subSystem=MyLib`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setRulebookPreviewContent(data.contents);
            // setRulebookModalOpen(false);
            setRulebookPreviewModalOpen(true);
        } catch (error) {
            console.error('Error fetching rulebook content:', error);
            alert('Failed to fetch rulebook content. Please try again.');
        }
    };

    const handleChatSubmit = async (index, additionalContext) => {
        const item = analysisResult[index];
        setIsReanalyzing({ ...isReanalyzing, [index]: true });

        try {
            const response = await fetch(CONSTANTS['endpoint_reanalyze_risk_item'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    "originalItem": item,
                    "additionalContext": additionalContext,
                    "rulebook": rulebook,
                    "uid": localStorage.getItem('userId'),
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reanalysisResult = await response.json();
            setReanalysisResults(prevResults => ({
                ...prevResults,
                [index]: { ...reanalysisResult, additionalContext: additionalContext }
            }));
        } catch (error) {
            console.error('Error re-analyzing risk item:', error);
        } finally {
            setIsReanalyzing({ ...isReanalyzing, [index]: false });
        }
    };

    const fileViewerProps = useMemo(() => ({
        selectedFileName: doc?.fileName,
        fileURL: fileURL,
    }), [doc?.fileName, fileURL]);

    useEffect(() => {
        // Save analysis history whenever analysisResult changes
        saveAnalysisHistory();
    }, [analysisResult, reanalysisResults]);

    const saveAnalysisHistory = async () => {
        if (!analysisResult || !file) return; // Avoid saving if no result or file

        try {
            const response = await fetch(CONSTANTS['endpoint_save_risk_analysis'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    docId: file,
                    uid: localStorage.getItem('userId'),
                    analysisResult: analysisResult,
                    reanalysisResults: reanalysisResults
                }),
            });

            if (!response.ok) {
                console.error('Error saving analysis history:', response.status);
            }
        } catch (error) {
            console.error('Error saving analysis history:', error);
        }
    };

    const loadAnalysisHistory = async () => {
        try {
            const response = await fetch(CONSTANTS['endpoint_fetch_risk_analysis'] + `?docId=${doc.docId}&uid=${localStorage.getItem('userId')}`);
            if (response.ok) {
                const history = await response.json();
                if (history && history.analysisResult) {
                    console.log("[INFO] Restoring Risk Analysis history");
                    setAnalysisResult(history.analysisResult);
                    setReanalysisResults(history.reanalysisResults || {});
                }
            }
        } catch (error) {
            console.error('Error loading analysis history:', error);
        }
    };


    useEffect(() => {
        fetchSavedRulebooks();
        if (doc) {
            setFile(doc.docId);
            const loadFile = async () => {
                doc.fileName = doc.fileName.replace(/\.\w+$/, ".pdf");
                const result = await fetchFileUrl(doc);
                if (result.error) {
                    console.error(result.error);
                } else {
                    setFileURL(result);
                }
            };
            loadFile();
        }
        loadAnalysisHistory();
    }, [doc]);

    const searchPluginInstance = searchPlugin();

    const transform = (slot) => ({
        ...slot,
        Download: () => <></>,
        DownloadMenuItem: () => <></>,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        Open: () => <></>,
        Print: () => <></>,
    });

    const renderToolbar = (Toolbar) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin(
        {
            sidebarTabs: (defaultTabs) => {
                return [defaultTabs[0]];
            },
            renderToolbar,
        }
    );
    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;


    const handleFindClick = (sourceText) => {
        const matches = fuzzyMatch(sourceText, docContents);
        if (matches) {
            searchPluginInstance.highlight(matches);
        } else {
            // Try matching a shorter snippet
            if (sourceText.length > 80) {
                const trimmedSourceText = sourceText.substring(0, 80);
                const trimmedMatches = fuzzyMatch(trimmedSourceText, docContents);
                if (trimmedMatches) {
                    searchPluginInstance.highlight(trimmedMatches);
                } else {
                    alert('No match found.');
                }
            } else {
                alert('No match found.');
            }
        }
    };


    return (
        <Box>
            <Header pageTitle="Risk Analysis" showBackHome={true} />
            <div className={styles.riskAnalysisPage}>
                <Box className={styles.mainContent}> {/* Main content area */}
                    {doc && (doc.fileName.endsWith('.docx') || doc.fileName.endsWith('.doc')) ? (
                        <FileViewerMemoized {...fileViewerProps} />
                    ) : (
                        <Box style={{
                            height: '92vh'
                        }}>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                                <Viewer
                                    fileUrl={fileURL ? fileURL : "https://pdfobject.com/pdf/sample.pdf"}
                                    httpHeaders={fileURL ? {
                                        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                                    } : {}}
                                    plugins={[searchPluginInstance, defaultLayoutPluginInstance]}
                                    onDocumentLoad={async (e) => {
                                        console.log('File URL:', fileURL);
                                        const pageNumbers = [...Array(e.doc.numPages + 1).keys()].slice(1);
                                        const pageContentPromises = pageNumbers.map(
                                            async (pageNumber) => {
                                                const page = await e.doc.getPage(pageNumber);
                                                return page.getTextContent();
                                            }
                                        );

                                        const pageContents = await Promise.all(pageContentPromises);
                                        const contents = pageContents.map((content) => content.items.map((item) => item.str).join('')).join('');
                                        setDocContents(contents);
                                    }}
                                />
                            </Worker>
                        </Box>
                    )}
                </Box>
                <Box className={styles.sidebar}> {/* Sidebar */}
                    <Button variant="contained" onClick={analyzeFile} style={{ width: "98%", margin: "1%" }} disabled={isLoading}>
                        {isLoading ? 'Analyzing Risks...' : 'Analyze File'}
                    </Button>
                    <Button variant="contained" style={{ width: "98%", margin: "1%" }} onClick={() => setRulebookModalOpen(true)}>
                        Manage Rulebooks
                    </Button>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingX: '8px', paddingTop: '8px' }}>
                        <Typography variant="h6">Rulebooks</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', height: '16px' }}>
                            {rulebook !== "None" && (
                                <Button onClick={handleRulebookDeselect}>Deselect</Button>
                            )}
                            {showRulebooks ? <ExpandLessIcon onClick={() => setShowRulebooks(!showRulebooks)} /> : <ExpandMoreIcon onClick={() => setShowRulebooks(!showRulebooks)} />}
                        </Box>
                    </Box>
                    <Collapse in={showRulebooks}>
                        {savedRulebooks.map((rulebookItem) => (
                            <ListItem key={rulebookItem} disablePadding>
                                <ListItemButton sx={{ width: '100%' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Array.isArray(rulebook) ? rulebook.includes(rulebookItem) : rulebook === rulebookItem}
                                                onChange={handleRulebookSelect}
                                                value={rulebookItem}
                                            />
                                        }
                                        label={rulebookItem}
                                        sx={{ width: '100%' }}
                                    />
                                    <Button size="small" onClick={() => fetchRulebookContent(rulebookItem)} sx={{ ml: 2 }}>Preview</Button>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </Collapse>

                    <div id="analysis-list" className={styles.riskList}>
                        {analysisResult.length > 0 ? analysisResult.map((item, index) => (
                            <div key={index} className={styles.riskItem}>
                                <Typography><b>Text: </b>{item.source_text}</Typography><br />
                                <Typography><b>Risk Level: </b>{item.risk_level}</Typography><br />
                                <Typography><b>Comment: </b>{item.comment}</Typography><br />
                                <Typography><b>Rules applied: </b>{item.rules}</Typography><br />
                                <Button variant='outlined' style={{ margin: '2px' }} onClick={() => handleFindClick(item.source_text)}>Locate</Button>
                                <Button variant='outlined' style={{ margin: '2px' }} onClick={() => {
                                    setShowChat({ ...showChat, [index]: !showChat[index] });
                                }}>
                                    {showChat[index] ? 'Hide Chat' : 'Chat'}
                                </Button>
                                {showChat[index] && (
                                    <div>
                                        <TextField
                                            value={chatInputs[index] || ''}
                                            onChange={(e) => setChatInputs({ ...chatInputs, [index]: e.target.value })}
                                            label="Additional Context"
                                            variant="outlined"
                                            fullWidth
                                            margin="dense"
                                        />
                                        <Button variant='contained' onClick={() => handleChatSubmit(index, chatInputs[index])} disabled={isReanalyzing[index]}>
                                            {isReanalyzing[index] ? 'Re-analyzing...' : 'Submit'}
                                        </Button>
                                    </div>
                                )}
                                {reanalysisResults[index] && (
                                    <div>
                                        <Typography><b>Re-analysis Result</b></Typography>
                                        <Typography><b>Additional Context: </b>{reanalysisResults[index].additionalContext}</Typography>
                                        <Typography><b>Risk Level: </b>{reanalysisResults[index].risk_level}</Typography>
                                        <Typography><b>Comment: </b>{reanalysisResults[index].comment}</Typography>
                                        <Typography><b>Rules applied: </b>{reanalysisResults[index].rules}</Typography>
                                    </div>
                                )}
                            </div>
                        )) : <Typography sx={{ m: 2 }} >Run analysis to see results</Typography>}
                    </div>

                </Box>
                <Modal
                    open={rulebookPreviewModalOpen}
                    onClose={() => {
                        setRulebookPreviewModalOpen(false);
                    }}
                    aria-labelledby="rulebook-preview-modal-title"
                    aria-describedby="rulebook-preview-modal-description"
                >
                    <Box sx={style} style={{ maxHeight: '80vh', width: '600px' }}>
                        <Typography id="rulebook-preview-modal-title" variant="h6" component="h2">
                            Rulebook Preview
                        </Typography>
                        <Button onClick={() => {
                            setRulebookPreviewModalOpen(false);
                            // setRulebookModalOpen(true);
                        }} style={{ position: 'absolute', top: '20px', right: '20px' }}>Close</Button>
                        <Typography id="rulebook-preview-modal-description" style={{ maxHeight: '75vh', overflowY: 'auto', whiteSpace: 'pre-line', margin: '8px auto' }}>
                            {rulebookPreviewContent}
                        </Typography>
                    </Box>
                </Modal>
                <Modal
                    open={rulebookModalOpen}
                    onClose={() => setRulebookModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Apply rulebooks
                        </Typography>
                        <List>
                            <div style={{ maxHeight: '200px', overflowY: 'auto', boxShadow: 'inset 0 2px 5px rgba(0,0,0,0.1)' }}>
                                {savedRulebooks.map((rulebookItem) => (
                                    <ListItem key={rulebookItem} disablePadding>
                                        <ListItemButton sx={{ width: '100%' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={Array.isArray(rulebook) ? rulebook.includes(rulebookItem) : rulebook === rulebookItem}
                                                        onChange={handleRulebookSelect}
                                                        value={rulebookItem}
                                                    />
                                                }
                                                label={rulebookItem}
                                                sx={{ width: '100%' }}
                                            />
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                                                <IconButton onClick={() => deleteRulebook(rulebookItem)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>
                                ))}</div>
                        </List>
                        <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
                            Upload New Rulebook
                        </Typography>
                        <TextField
                            type="file"
                            accept=".txt"
                            onChange={uploadRulebook}
                            sx={{ mt: 1, boxShadow: 'inset 0 2px 5px rgba(0,0,0,0.1)' }}
                        />
                        <Button variant="contained" onClick={() => setRulebookModalOpen(false)} sx={{ mt: 2 }}>
                            Confirm
                        </Button>
                    </Box>
                </Modal>
            </div>
        </Box>
    );
};

export default RiskAnalysisPage;