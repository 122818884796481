import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AdminDashboardPage from './pages/AdminDashboardPage';
import ChatPage from './pages/ChatPage';
import RiskAnalysisPage from './pages/RiskAnalysisPage';
import CompareFilesPage from './pages/CompareFilesPage';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import NotFoundPage from './pages/NotFoundPage';
import RCAFormPage from './pages/RCAFormPage';
import AutoFillPage from './pages/AutoFillPage';
import UserProfilePage from './pages/UserProfilePage';
import { PAGES } from './routes/routerConfig';
import RequireAuth from './RequireAuth';
import HomePage from './pages/HomePage';
import { FiefAuthProvider } from '@fief/fief/react';
import { FIEF_SERVER_URL, FIEF_CLIENT_ID } from './services/fief';
import Callback from './Callback';

function App() {
  return (
    <FiefAuthProvider baseURL={FIEF_SERVER_URL} clientId={FIEF_CLIENT_ID}>
      <Router>
        <Routes>
          <Route path={PAGES['idoc-rca-form']} element={<RCAFormPage />} />
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/callback" element={<Callback />} />
          <Route
            path="/home"
            element={
              <RequireAuth>
                <HomePage />
              </RequireAuth>
            }
          />
          <Route
            path={PAGES['profile']}
            element={
              <RequireAuth>
                <UserProfilePage />
              </RequireAuth>
            }
          />
          <Route
            path={PAGES['admin']}
            element={
              <RequireAuth>
                <AdminDashboardPage />
              </RequireAuth>
            }
          />
          <Route
            path={PAGES['board']}
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
          <Route
            path={PAGES['analysis']}
            element={
              <RequireAuth>
                <RiskAnalysisPage />
              </RequireAuth>
            }
          />
          <Route
            path={PAGES['compare']}
            element={
              <RequireAuth>
                <CompareFilesPage />
              </RequireAuth>
            }
          />
          <Route
            path={PAGES['chat']}
            element={
              <RequireAuth>
                <ChatPage />
              </RequireAuth>
            }
          />
          <Route
            path={PAGES['fill']}
            element={
              <RequireAuth>
                <AutoFillPage />
              </RequireAuth>
            }
          />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </FiefAuthProvider>
  );
}

export default App;
